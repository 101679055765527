<template>
    <v-dialog v-model="$store.state.dialogPermissao" persistent max-width="500px">
        <v-btn slot="activator" color="primary" dark>
            <v-icon left>add_circle_outline</v-icon> 
            Nova Permissão
        </v-btn>
        <v-card>
            <v-toolbar card prominent extended color="primary" dark="">
                <v-toolbar-title class="body-2">Cadastro de permissão</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="$store.state.dialogPermissao = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
			<v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                    <v-layout row wrap>
                        <v-flex xs12 sm12 md12>
                            <v-select
                                v-model="$store.state.permissionEdit.idSetor"
                                :items="$store.state.setores"
                                label="Setor *"
                                required
                                item-text="NmSetor"
                                item-value="CdSetor"
                                ></v-select>
                        </v-flex>
                        <v-flex lg12 sm12>
                            <v-text-field label="Path" v-model="$store.state.permissionEdit.path">
                            </v-text-field>
                        </v-flex>
                        <v-flex lg12 sm12>
                            <v-text-field label="Group" v-model="$store.state.permissionEdit.group">
                            </v-text-field>
                        </v-flex>
                    </v-layout>        
                </v-form>
            </v-card-text>  
            <v-card-actions class="pb-3">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="cancelar()">Cancelar</v-btn>
                <v-btn color="primary"  @click.native="salvar()" v-if="!$store.state.permissionEdit.id">Salvar</v-btn>
                <v-btn color="primary"  @click.native="update()" v-else>Atualizar</v-btn>
            </v-card-actions>	
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name:'Permissao',
    data () {
        return {
            dialog: false,
            valid: true,
        }
    },
    methods : {
        salvar (){
            let permission = this.$store.state.permissionEdit
            console.log(permission)
            this.$http.post(this.$store.state.urlBase+'v1/rh/permissoes',permission)
                .then(response => {
                    if(typeof response.data.errors !== 'undefined'){
                          //Erros de Validação
                          for (var error in response.data.errors) {
                              toastr.error(response.data.errors[error],'Permissões')
                          }
                     }else{
                         this.atualizar()
                     }
            })
        },
        update (){
            let permission = this.$store.state.permissionEdit
            this.$http.put(this.$store.state.urlBase+'v1/rh/permissoes/'+permission.id,permission)
                 .then(response => {
                    if(typeof response.data.errors !== 'undefined'){
                          //Erros de Validação
                          for (var error in response.data.errors) {
                              toastr.error(response.data.errors[error],'Permissões')
                          }
                     }else{
                         this.atualizar()
                     }
            })
        },
        cancelar() {
            this.$store.state.dialogPermissao = false
            this.$store.state.permissionEdit = {}
        },
        atualizar () {
            this.$store.state.permissionEdit = {}
            window.getApp.$emit('APP_PERMISSOES_SUCATA_UPDATED')
            this.$store.state.dialogPermissao = false
        }
    },
    created () {
        // console.log(!this.$store.state.categoria.id)
    }
}
</script>
