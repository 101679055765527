<template>
  <v-app id="login" class="primary">
    <v-content>
      <!--  -->
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <!--  -->
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img
                    src="/img/ALIGNERPLAN.gif"
                    alt="ALIGNERPLAN"
                    class="logo-empresa mb-3"
                  />
                </div>
                <v-form>
                  <v-text-field
                    append-icon="person"
                    name="login"
                    :label="$t('Username')"
                    type="text"
                    v-model="model.username"
                    autocomplete="on"
                  ></v-text-field>
                  <v-text-field
                    append-icon="lock"
                    name="password"
                    :label="$t('Senha')"
                    id="password"
                    type="password"
                    autocomplete="on"
                    v-model="model.password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-container grid-list-md text-xs-center class="pa-0">
                  <v-layout row>
                    <v-flex xs12>
                      <v-btn
                        block
                        color="primary"
                        @click="login"
                        :loading="loading"
                        >{{$t('Login')}}</v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <credenciamento-modal></credenciamento-modal>
                  <recuperar-senha class="py-3"></recuperar-senha>
                  <v-layout row justify-center class="mt-2">
                    <v-menu top>
                      <template #activator="{ on: menu }">
                        <v-btn v-on="{ ...menu }" flat color="gray" >
                          <v-img
                            class="btn-language"
                            :src="flag"
                            aspect-ratio="1"
                          ></v-img>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-tile @click="setLanguage(item)" v-for="item in $store.state.languages"
                        :key="item.locale">
                          <v-list-tile-avatar>
                            <v-avatar tile size="24" color="gray">
                              <img
                                :src="item.flag"
                                alt="avatar"
                              />
                            </v-avatar>
                          </v-list-tile-avatar>
                          <v-list-tile-title>{{item.label}}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </v-layout>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>
<!-- Clicar na liguagem e guardar no localStorage-->
<script>
import toastr from "toastr";
import API from "@/api/";
// import FotoCliente from "./comercial/FotoCliente.vue";
import CredenciamentoModal from "../components/CredenciamentoModal.vue";
import RecuperarSenha from "../components/RecuperarSenha.vue";

export default {
  components: { CredenciamentoModal, RecuperarSenha },
  data: () => ({
    loading: false,
    model: {
      username: "",
      password: "",
    },
    items: [
      { title: "Click Me1" },
      { title: "Click Me2" },
      { title: "Click Me3" },
      { title: "Click Me4" },
    ],
  }),
  computed:{
    flag () {
      return this.$store.state.locale.flag;
    }
  },
  methods: {
    setLanguage(language){
      window.localStorage.setItem('locale', JSON.stringify(language));
      this.$store.state.locale = language
      this.$i18n.locale = language.locale
    },
    login() {
      // console.log(this)
      this.loading = true;
      // this.$http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      // this.$http.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token';

      this.$http
        .post(this.$store.state.urlBase + "login", {
          // .post(this.$store.state.urlBase + "auth/login/" , {
          email: this.model.username,
          password: this.model.password,
        })
        .then((response) => {
          // console.log(response)
          if (response.data.token) {
            //Login com sucesso
            this.$session.start();
            this.$session.set("user", response.data);
            this.$store.state.user = response.data;
            this.$store.commit("setUser", response.data);
            API.setUserStorage(response.data);
            window.user = response.data;
            // window.getApp.$emit("APP_LOJA_GET");
            // window.getApp.$emit("APP_SAIDAS_CLIENTE_GET");
            window.getApp.$emit("APP_LOGIN_SUCCESS");
            //Login Não Existe
          } else if (typeof response.data.errors !== "undefined") {
            //Erros de Validação
            for (var error in response.data.errors) {
              toastr.error(response.data.errors[error], "AlignerPlan");
            }
            this.loading = false;
          }
          //Zerando o Formulário
          this.model.username = "";
          this.model.password = "";
          // // $('.form-login').show('slow')
          // this.$store.commit('loading',false)
        });
      // .catch(error => {
      //     console.log(error)
      //     this.$store.commit('loading',false)
      //     toastr.error('Falha de conexão com a API','AlignerPlan')
      // })
    },
  },
  created() {
    let retrievedObject = localStorage.getItem('locale');
    const locale = JSON.parse(retrievedObject);

    if (locale && this && (typeof this.setLanguage === 'function')) {
      this.setLanguage(locale)
    }
  },
};
</script>
<style lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
.nome-empresa {
  line-height: 45px;
}
.logo-empresa {
  border-radius: 5px;
  width: 100%;
}
.v-text-field input{
    opacity: 1;
  }
.v-responsive__sizer{
  flex: 0 0 0px;
}
.btn-language > .v-image__image.v-image__image--cover{
  width: 100%!important;
}
</style>
