<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg12>
          <v-card>
            <v-toolbar flat color="white">
              <!-- <v-toolbar-title>My CRUD</v-toolbar-title>
                            <v-divider
                                class="mx-2"
                                inset
                                vertical
                            ></v-divider> -->
              <v-spacer></v-spacer>
              <usuario></usuario>
            </v-toolbar>
            <v-toolbar card color="white">
              <v-text-field
                flat
                solo
                prepend-icon="search"
                placeholder="Digite algo"
                v-model="search"
                hide-details
                class=""
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :rows-per-page-items="[10, 25, 50, { text: 'All', value: -1 }]"
                class="elevation-1"
                item-key="email"
                select-all
                v-model="complex.selected"
              >
                <template slot="items" slot-scope="props">
                  <td>
                    <v-checkbox
                      primary
                      hide-details
                      v-model="props.selected"
                    ></v-checkbox>
                  </td>
                  <!-- <td>
                                <v-avatar size="32">
                                <img :src="props.item.avatar" alt="">
                                </v-avatar> 
                            </td> -->
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.email }}</td>
                  <td>{{ getTipo(props.item.tipo) }}</td>
                  <td>
                    {{
                      props.item.updated_at | formatDate("DD/MM/YYYY HH:mm:ss")
                    }}
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="success"
                          depressed
                          icon
                          fab
                          dark
                          small
                          v-on="on"
                          @click.native="autorizar(props.item)"
                          v-if="props.item.autorizado !== 1"
                        >
                          <v-icon>how_to_reg</v-icon>
                        </v-btn>
                      </template>
                      <span>Autorizar usuário</span>
                    </v-tooltip>
                    <v-btn
                      depressed
                      outline
                      icon
                      fab
                      dark
                      color="primary"
                      small
                      @click.native="editar(props.item)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn
                      depressed
                      outline
                      icon
                      fab
                      dark
                      color="pink"
                      small
                      @click.native="excluir(props.item)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import toastr from "toastr";
import * as moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");
import Usuario from "@/components/usuario/Usuario.vue";

export default {
  components: {
    Usuario,
  },
  data() {
    return {
      dialog: false,
      search: "",
      complex: {
        selected: [],
        headers: [
          {
            text: "ID",
            value: "id",
          },
          {
            text: "Nome Completo",
            value: "name",
          },
          {
            text: "Email",
            value: "email",
          },
          {
            text: "Tipo",
            value: "tipo",
          },
          {
            text: "Última atualização",
            value: "updated_at",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    getUsuarios() {
      toastr.success("Atualizando lista de usuários...", "Artaligner");
      this.$http.get(this.$store.state.urlBase + "users").then((response) => {
        this.complex.items = response.data;
      });
    },
    getTipo(tipo){
      let tipoInt = parseInt(tipo) -1
      let tipos = [
        this.$t("Administrador"),this.$t("Planejador"),this.$t("Revisor"),this.$t("Credenciado")
      ]

      return tipos[tipoInt]
    },
    editar(user) {
      this.$store.state.userEdit = user;
      this.$store.state.dialogUser = true;
    },
    autorizar(user) {
      var http = this.$http;
      var url = this.$store.state.urlBase;
      toastr.info(
        "<br /><button type='button' value='Sim' style='padding:2px 7px;border:1px solid;'>Sim</button><button type='button'  value='Não' style='padding:2px 7px;border:1px solid;margin-left:10px'>Não</button>",
        "Deseja realmente autorizar este usuário?",
        {
          allowHtml: true,
          onclick: function (toast) {
            var value = toast.target.value;

            if (value == "Sim") {
              alert("Iniciando a autorização... aguarde!");
              alertExclusao();
              http.put(url + "users/autorizar/" + user.id).then((response) => {
                toastr.success(response.data.message, "Artaligner");
                window.getApp.$emit("APP_RECORD_UPDATED");
              });
            }
            toastr.remove();
            toastr.clear();
          },
        }
      );
    },
    excluir(user) {
      var http = this.$http;
      var url = this.$store.state.urlBase;
      toastr.warning(
        "<br /><button type='button' value='Sim' style='padding:2px 7px;border:1px solid;'>Sim</button><button type='button'  value='Não' style='padding:2px 7px;border:1px solid;margin-left:10px'>Não</button>",
        "Deseja realmente excluir este usuário?",
        {
          allowHtml: true,
          onclick: function (toast) {
            var value = toast.target.value;

            if (value == "Sim") {
              alert("Iniciando a exclusão... aguarde!");
              alertExclusao();
              http.delete(url + "users/" + user.id).then((response) => {
                toastr.success(response.data.message, "Artaligner");
                window.getApp.$emit("APP_RECORD_UPDATED");
              });
            }
            toastr.remove();
            toastr.clear();
          },
        }
      );
    },
  },
  created() {
    delete window.getApp._events.APP_RECORD_UPDATED;
    this.getUsuarios();
    window.getApp.$on("APP_RECORD_UPDATED", this.getUsuarios);
    window.getApp.$on("APP_USER_UPDATED", this.getUsuarios);
    window.alertExclusao = function () {
      toastr.success("Aguarde a exclusão do usuário...", "Artaligner");
    };
  },
  updated() {
    //   this.getCategorias ()
  },
  filters: {
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
};
</script>