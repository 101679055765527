var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"pageTable"}},[_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"sm12":""}},[_c('h3',[_vm._v("Planejamentos")])]),_c('v-flex',{attrs:{"lg12":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),(_vm.user.tipo === 4)?_c('v-btn',{attrs:{"color":"primary","to":"submeta"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add_circle_outline")]),_vm._v(" Novo Caso ")],1):_vm._e(),(_vm.user.tipo !== 4)?_c('planejamento-modal'):_vm._e()],1),_c('v-toolbar',{attrs:{"card":"","color":"white"}},[_c('v-text-field',{attrs:{"flat":"","solo":"","prepend-icon":"search","placeholder":"Digite algo","hide-details":"","classeantiga":"hidden-sm-and-down"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("filter_list")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.user.tipo === 4 ? _vm.complex.headersCredenciado : _vm.complex.headers,"search":_vm.search,"no-data-text":_vm.$t('noDataText'),"items":_vm.complex.items,"rows-per-page-items":[
                10,
                25,
                50,
                { text: 'Todos', value: -1 },
              ],"rows-per-page-text":"Linhas por página","item-key":"name","pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.item.id))]),(_vm.user.tipo !== 4)?_c('td',[_vm._v(_vm._s(props.item.cliente.name))]):_vm._e(),_c('td',[_vm._v(_vm._s(props.item.paciente))]),_c('td',[(
                      _vm.user.tipo === 4 &&
                      props.item.status === 'Devolvido para o planejamento'
                    )?_c('v-chip',{attrs:{"dark":"","color":_vm.getColor(props.item.status)}},[_vm._v(_vm._s(_vm.$t("Revisao em analise")))]):_c('v-chip',{attrs:{"dark":"","color":_vm.getColor(props.item.status)}},[_vm._v(_vm._s(props.item.status))])],1),(
                      _vm.user.tipo !== 4)?_c('td',[_vm._v(_vm._s(props.item.origem))]):_vm._e(),(
                      _vm.user.tipo !== 4)?_c('td',[_vm._v(_vm._s(props.item.user.name))]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(props.item.data_entrega,"DD/MM/Y")))]),_c('td',[(
                      _vm.user.tipo === 4 &&
                      (props.item.status === 'Planejamento em produção' ||
                        props.item.status === 'Devolvido para Revisão' ||
                        props.item.status === 'Arquivos desaprovados' ||
                        props.item.status === 'Arquivos em análise' ||
                        props.item.status === 'Planejamento em edição')
                    )?_c('planejamento-view',{attrs:{"planejamento":props.item}}):_vm._e(),(
                      (_vm.user.tipo === 4 &&
                      props.item.status === 'Enviado para aprovação')
                    )?_c('aprovacao-view',{attrs:{"planejamento":props.item}}):_vm._e(),(props.item.status === 'Aprovado')?_c('aprovado-view',{attrs:{"planejamento":props.item}}):_vm._e(),(
                      _vm.user.tipo !== 4 &&
                      (props.item.status === 'Planejamento em produção' ||
                        props.item.status === 'Planejamento em edição' ||
                        props.item.status === 'Devolvido para Revisão')
                    )?_c('v-btn',{attrs:{"depressed":"","outline":"","icon":"","fab":"","dark":"","color":"primary","small":""},nativeOn:{"click":function($event){return _vm.editar(props.item)}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e(),(props.item.status !== 'Aprovado')?_c('v-btn',{attrs:{"depressed":"","outline":"","icon":"","fab":"","dark":"","color":"pink","small":""},nativeOn:{"click":function($event){return _vm.excluir(props.item)}}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e()],1)]}}]),model:{value:(_vm.complex.selected),callback:function ($$v) {_vm.$set(_vm.complex, "selected", $$v)},expression:"complex.selected"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }