<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h3>Planejamentos</h3>
        </v-flex>
        <v-flex lg12>
          <v-card>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-btn color="primary" to="submeta" v-if="user.tipo === 4">
                <v-icon left>add_circle_outline</v-icon>
                Novo Caso
              </v-btn>
              <planejamento-modal v-if="user.tipo !== 4"></planejamento-modal>
            </v-toolbar>
            <v-toolbar card color="white">
              <v-text-field
                flat
                solo
                prepend-icon="search"
                placeholder="Digite algo"
                v-model="search"
                hide-details
                class=""
                classeantiga="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="
                  user.tipo === 4 ? complex.headersCredenciado : complex.headers
                "
                :search="search"
                :no-data-text="$t('noDataText')"
                :items="complex.items"
                :rows-per-page-items="[
                  10,
                  25,
                  50,
                  { text: 'Todos', value: -1 },
                ]"
                rows-per-page-text="Linhas por página"
                class="elevation-1"
                item-key="name"
                :pagination.sync="pagination"
                v-model="complex.selected"
              >
                <template
                  slot="items"
                  slot-scope="props"
                  @click.native="editar(props.item)"
                >
                  <td>{{ props.item.id }}</td>
                  <td v-if="user.tipo !== 4">{{ props.item.cliente.name }}</td>
                  <td>{{ props.item.paciente }}</td>
                  <!-- <td>
                    <v-btn
                      flat
                      depressed
                      outline
                      icon
                      fab
                      color="primary"
                      small
                      @click.native="abrirVisor(props.item)"
                    >
                      <v-icon>link</v-icon>
                    </v-btn>
                  </td> -->
                  <td>
                    <v-chip
                      dark
                      :color="getColor(props.item.status)"
                      v-if="
                        user.tipo === 4 &&
                        props.item.status === 'Devolvido para o planejamento'
                      "
                      >{{ $t("Revisao em analise") }}</v-chip
                    >
                    <v-chip dark :color="getColor(props.item.status)" v-else>{{
                      props.item.status
                    }}</v-chip>
                  </td>
                  <td v-if="
                        user.tipo !== 4">{{ props.item.origem }}</td>
                  <td v-if="
                        user.tipo !== 4">{{ props.item.user.name }}</td>
                  <td>{{ props.item.data_entrega | formatDate("DD/MM/Y") }}</td>
                  <td>
                    <!-- Planejamento -->
                    <planejamento-view
                      :planejamento="props.item"
                      v-if="
                        user.tipo === 4 &&
                        (props.item.status === 'Planejamento em produção' ||
                          props.item.status === 'Devolvido para Revisão' ||
                          props.item.status === 'Arquivos desaprovados' ||
                          props.item.status === 'Arquivos em análise' ||
                          props.item.status === 'Planejamento em edição')
                      "
                    ></planejamento-view>
                    <!-- Enviado para aprovação -->
                    <aprovacao-view
                      :planejamento="props.item"
                      v-if="
                        (user.tipo === 4 &&
                        props.item.status === 'Enviado para aprovação')
                      "
                    ></aprovacao-view>
                    <!-- Aprovado -->
                    <aprovado-view
                      :planejamento="props.item"
                      v-if="props.item.status === 'Aprovado'"
                    ></aprovado-view>
                    <!-- Editar -->
                    <v-btn
                      depressed
                      outline
                      icon
                      fab
                      dark
                      color="primary"
                      small
                      @click.native="editar(props.item)"
                      v-if="
                        user.tipo !== 4 &&
                        (props.item.status === 'Planejamento em produção' ||
                          props.item.status === 'Planejamento em edição' ||
                          props.item.status === 'Devolvido para Revisão')
                      "
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <!-- Excluir -->
                    <v-btn
                      v-if="props.item.status !== 'Aprovado'"
                      depressed
                      outline
                      icon
                      fab
                      dark
                      color="pink"
                      small
                      @click.native="excluir(props.item)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import toastr from "toastr";
import PlanejamentoModal from "@/pages/comercial/Planejamento.vue";
import * as moment from "moment";
import "moment/locale/pt-br";
import PlanejamentoView from "@/pages/artaligner/Casos/Planejamento.vue";
import AprovacaoView from "@/pages/artaligner/Casos/Aprovacao.vue";
import AprovadoView from "@/pages/artaligner/Casos/Aprovado.vue";
moment.locale("pt-BR");

export default {
  name: "Planejamento",
  components: {
    PlanejamentoModal,
    PlanejamentoView,
    AprovacaoView,
    AprovadoView,
  },
  computed: {
    user() {
      // console.log(this.$store.state.user)
      return this.$store.state.user;
    },
  },
  data() {
    return {
      dialog: false,
      search: "",
      complex: {
        selected: [],
        headers: [
          {
            text: "ID",
            value: "id",
          },
          {
            text: "Cliente",
            value: "cliente.name",
          },
          {
            text: "Paciente",
            value: "paciente",
          },
          {
            text: "Status",
            value: "status",
          },
          {
            text: "Origem",
            value: "origem",
          },
          {
            text: "Usuário",
            value: "user.name",
          },
          {
            text: "Entrega",
            value: "data_entrega",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        headersCredenciado: [
          {
            text: "ID",
            value: "id",
          },
          {
            text: "Paciente",
            value: "paciente",
          },
          {
            text: "Status",
            value: "status",
          },
          {
            text: "Data da Entrega",
            value: "data_entrega",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
      pagination: {
        sortBy: "id", // The field that you're sorting by
        descending: true,
      },
    };
  },
  methods: {
    abrirVisor(item) {
      window
        .open(this.$store.state.urlBaseWeb + "visor/" + item.id, "_blank")
        .focus();
    },
    getColor(status) {
      let cor = "";
      switch (status) {
        case "Aprovado":
          cor = "green";
          break;
        case "Planejamento em produção":
          cor = "blue lighten-2";
          break;
        case "Enviado para aprovação":
          cor = "red";
          break;
        case "Devolvido para Revisão":
        case "Planejamento em Revisão":
          cor = "orange";
          break;
        default:
          cor = "default";
      }
      return cor;
    },
    getList() {
      this.complex.items = [];
      toastr.success(
        "Atualizando Lista de Planejamentos...",
        "AlignerPlan"
      );
      this.$http
        .get(this.$store.state.urlBase + "planejamentos")
        //  .get(this.$store.state.urlBase + "user")
        .then((response) => {
          this.complex.items = response.data;
          // console.log(response.data);
        });
    },
    editar(item) {
      //window.getApp.$emit("APP_CLIENTES_UPDATED");
      //window.getApp.$emit("APP_USER_UPDATED");
      console.log(item);
      this.$store.commit("abrirModalPlanejamento", item);
    },
    excluir(planejamento) {
      var http = this.$http;
      var url = this.$store.state.urlBase;
      toastr.warning(
        "<br /><button type='button' value='Sim' style='padding:2px 7px;border:1px solid;'>Sim</button><button type='button'  value='Não' style='padding:2px 7px;border:1px solid;margin-left:10px'>Não</button>",
        "Deseja realmente excluir este planejamento?",
        {
          allowHtml: true,
          onclick: function (toast) {
            var value = toast.target.value;

            if (value == "Sim") {
              alert("Iniciando a exclusão... aguarde!");
              alertExclusaoPlanejamento();
              http
                .delete(url + "planejamentos/" + planejamento.id)
                .then((response) => {
                  toastr.success(response.data.message, "AlignerPlan");
                  window.getApp.$emit("APP_RECORD_UPDATED");
                });
            }
            toastr.remove();
            toastr.clear();
          },
        }
      );
    },
  },
  created() {
    this.getList();
    // window.getApp.$emit('APP_COMPRADORES_GET')
    // console.log(window.getApp._events)
    delete window.getApp._events.APP_PRECOS_SUCATA_UPDATED;
    delete window.getApp._events.APP_RECORD_UPDATED;
    window.getApp.$on("APP_PLANEJAMENTOS_UPDATED", this.getList);
    window.getApp.$on("APP_RECORD_UPDATED", this.getList);
    window.alertExclusaoPlanejamento = function () {
      toastr.success(
        "Aguarde a exclusão do Planejamento...",
        "AlignerPlan"
      );
    };
  },
  updated() {
    //   this.getCategorias ()
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toFixed(2);
    },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
};
</script>