<template>
  <v-dialog v-model="dialog" hide-overlay width="600px">
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn raised text tile v-bind="attrs" v-on="on">Recuperar Senha</v-btn> -->
      <a v-bind="attrs" v-on="on" >{{$t('Recuperar senha')}}</a>
    </template>
    <v-tabs v-model="currentItem" hide-slider grow>
      <v-tabs-items v-model="currentItem">
        <v-tab-item value="recuperarSenha">
          <v-card>
            <v-card-title class="primary py-4 title white--text">
              {{$t('Recuperar senha')}}
            </v-card-title>
            <v-container grid-list-sm class="pa-4">
              <v-form ref="recuperar" v-model="valid" lazy-validation>
                <v-layout row wrap>
                  <v-flex xs12 align-center justify-space-between>
                    <v-layout align-center>
                      <v-avatar size="40px" class="mr-3">
                        <v-icon class="grey lighten-2" dark>mdi-email</v-icon>
                      </v-avatar>
                      <v-text-field
                        :label="$t('Email')"
                        required
                        :rules="rules"
                        persistent-hint
                        autocomplete="on"
                        :placeholder="$t('Digite o seu e-mail usado para efetuar o login na plataforma')"
                        v-model="email"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <v-card-actions>
              <!-- <v-btn flat color="primary">More</v-btn> -->
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">Cancelar</v-btn>
              <v-btn
                raised
                color="primary"
                @click.native="recuperar"
                :loading="loading"
                >Recuperar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item value="token">
          <v-card>
            <v-card-title class="primary py-4 title white--text">
              {{$t('Confirmação do Token')}}
            </v-card-title>
            <v-container grid-list-sm class="pa-4">
              <v-form ref="token" v-model="valid" lazy-validation>
                <v-layout row wrap>
                  <v-flex xs12 align-center justify-space-between>
                    <v-text-field
                      append-icon="mdi-token"
                      label="Token"
                      :rules="[(v) => !!v || $t('O campo Token é obrigatorio!')]"
                      required
                      persistent-hint
                      v-model="token"
                      autocomplete="on"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <v-card-actions>
              <!-- <v-btn flat color="primary">More</v-btn> -->
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">Cancelar</v-btn>
              <v-btn
                raised
                color="primary"
                @click.native="confirmarToken"
                :loading="loading"
                >Confirmar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item value="redefinir">
          <v-card>
            <v-card-title class="primary py-4 title white--text">
              {{$t('Redefinir a senha')}}
            </v-card-title>
            <v-container grid-list-sm class="pa-4">
              <v-form ref="redefinir" v-model="valid" lazy-validation>
                <v-layout row wrap>
                  <v-flex xs12 align-center justify-space-between>
                    <v-layout align-center>
                      <v-flex xs12 align-center justify-space-between>
                        <v-text-field
                          append-icon="mdi-lock"
                          :label="$t('Nova Senha')"
                          :rules="[(v) => !!v || $t('O campo Nova Senha é obrigatorio!')]"
                          required
                          persistent-hint
                          v-model="senha"
                          autocomplete="new-password"
                          type="password"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout align-center>
                      <v-flex xs12 align-center justify-space-between>
                        <v-text-field
                          append-icon="mdi-lock"
                          :label="$t('Confirmar Nova Senha')"
                          required
                          autocomplete="new-password"
                          persistent-hint
                          :rules="[(v) => !!v || $t('O campo Confirmar Senha é obrigatorio!'), confirmacaoSenha]"
                          v-model="confirmarSenha"
                          type="password"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <v-card-actions>
              <!-- <v-btn flat color="primary">More</v-btn> -->
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">Cancelar</v-btn>
              <v-btn
                raised
                color="primary"
                @click.native="redefinir"
                :loading="loading"
                >{{$t('Redefinir')}}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
import CredenciamentoModal from "./CredenciamentoModal.vue";
export default {
  components: { CredenciamentoModal },
  name: "RecuperarSenhaModal",
  data() {
    return {
      currentItem: "recuperarSenha",
      // currentItem: "token",
      dialog: false,
      email: "",
      senha: "",
      confirmarSenha:"",
      token: "",
      valid: true,
      loading: false,
      rules: [
        (v) => !!v || this.$t("O campo E-mail é obrigatorio!"),
        (v) =>
          /.+@.+/.test(v) || this.$t("O campo E-mail precisa ser um endereço válido!"),
      ],
    };
  },
  computed:{
    confirmacaoSenha () {
      return () => (this.senha === this.confirmarSenha) || this.$t('Senha e confirmação de senha devem ser iguais')
    }
  },
  props: {},
  methods: {
    fechar() {
      this.dialog = false;
    },
    redefinir(){
      let token = this.token;
      let emailAddress = this.email;
      let senha = this.senha;
      let confirmarSenha = this.confirmarSenha;

      if (this.$refs.token.validate()) {
        this.loading = true;
        this.$http
          .post(this.$store.state.urlBase + "redefinirSenha", {
            token: token,
            password: senha,
            email: emailAddress,
            password_confirmation: confirmarSenha
          })
          .then((response) => {
            console.log(response);
            if (response.data.valid) {
              toastr.success(
                this.$t("Senha Redefinida com sucesso!"),
                "AlignerPlan"
              );
              this.$refs.recuperar.reset();
              this.loading = false;
              this.currentItem = "recuperarSenha";
              this.dialog = false;
              
            } else if (typeof response.data.errors !== "undefined") {
              //Erros de Validação
              for (var error in response.data.errors) {
                toastr.error(
                  this.$t(response.data.errors[error]),
                  "AlignerPlan"
                );
              }
              this.loading = false;
            } else {
              this.loading = false;
              toastr.error(
                this.$t("Erro ao redefinir senha."),
                "AlignerPlan"
              );
            }
          })
          .catch((error) => {
            this.loading = false;
            toastr.error(
              this.$t("Erro ao redefinir senha. Entre em contato com o AlignerPlan"),
              "AlignerPlan"
            );
          });
      }else {
        toastr.error(
          this.$t("Preencha todos os campos obrigatórios antes de enviar!"),
          "AlignerPlan"
        );
      }
    },
    confirmarToken(){
      var token = this.token;
      var emailAddress = this.email;
      if (this.$refs.token.validate()) {
        this.loading = true;
        this.$http
          .post(this.$store.state.urlBase + "confirmarToken", {
            token: token,
            email: emailAddress
          })
          .then((response) => {
            console.log(response);
            if (response.data.valid) {
              toastr.success(
                this.$t("Token Validado! informe sua nova senha."),
                "AlignerPlan"
              );
              // this.$refs.recuperar.reset();
              this.loading = false;
              // this.dialog = false;
              this.currentItem = "redefinir";
            } else if (typeof response.data.errors !== "undefined") {
              //Erros de Validação
              for (var error in response.data.errors) {
                toastr.error(
                  this.$t(response.data.errors[error]),
                  "AlignerPlan"
                );
              }
              this.loading = false;
            } else {
              this.loading = false;
              toastr.error(
                this.$t("Token Inválido."),
                "AlignerPlan"
              );
            }
          })
          .catch((error) => {
            this.loading = false;
            toastr.error(
              this.$t("Token Inválido."),
              "AlignerPlan"
            );
          });
      }else {
        toastr.error(
          this.$t("Preencha todos os campos obrigatórios antes de enviar!"),
          "AlignerPlan"
        );
      }
    },
    recuperar: function () {
      var emailAddress = this.email;
      if (this.$refs.recuperar.validate()) {
        this.loading = true;
        console.log(emailAddress);
        this.$http
          .post(this.$store.state.urlBase + "recuperarSenha", {
            email: emailAddress,
          })
          .then((response) => {
            console.log(response);
            console.log(response.data.valid);
            if (response.data.valid) {
              toastr.success(
                this.$t("Um e-mail de recuperação foi enviado para o endereço informado!"),
                "AlignerPlan"
              );
              // this.$refs.recuperar.reset();
              this.loading = false;
              // this.dialog = false;
              this.currentItem = "token";
            } else if (typeof response.data.errors !== "undefined") {
              //Erros de Validação
              for (var error in response.data.errors) {
                toastr.error(
                  this.$t(response.data.errors[error]),
                  "AlignerPlan"
                );
              }
              this.loading = false;
            } else {
              this.loading = false;
              toastr.error(
                this.$t("Não foi possível encontrar o e-mail informado, ou ocorreu algum erro ao processar a recuperação."),
                "AlignerPlan"
              );
            }
          })
          .catch((error) => {
            this.loading = false;
            toastr.error(
              this.$t("Não foi possível encontrar o e-mail informado, ou ocorreu algum erro ao processar a recuperação."),
              "AlignerPlan"
            );
          });
      } else {
        toastr.error(
          this.$t("Preencha todos os campos obrigatórios antes de enviar!"),
          "AlignerPlan"
        );
      }

      // auth
      //   .sendPasswordResetEmail(emailAddress)
      //   .then(function () {
      //     // Email sent.
      //     toastr.success(
      //       "Um e-mail de recuperação foi enviado para o endereço informado!",
      //       "Confabular"
      //     );
      //     window.fechar();
      //   })
      //   .catch(function (error) {
      //     // An error happened.
      //     console.log(error);
      //     toastr.error(
      //       "Não foi possível encontrar o e-mail informado, ou ocorreu algum erro ao processar a recuperação.",
      //       "UCP"
      //     );
      //     toastr.error(error.message, "UCP");
      //   });
    },
  },
  created() {
    window.fechar = this.fechar;
  },
};
</script>
<style scoped lang="css">
.v-tabs[data-v-1ec5c8bc] >>> .theme--light.v-tabs__bar, .v-tabs[data-v-2b503ff5] >>> .theme--light.v-tabs__bar,.v-tabs >>> .theme--light.v-tabs__bar{
  display: none;
}
</style>