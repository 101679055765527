<template>
  <v-app id="credenciamento" class="primary">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md8 lg8>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img
                    src="https://artaligner.com.br/wp-content/uploads/2018/01/logo.png"
                    alt="ArtAligner"
                    class="logo-empresa"
                  />
                  <h1
                    class="flex my-4 primary--text text-xs-center nome-empresa"
                  >
                    {{$t('Registro de Novo Usuário')}} 
                  </h1>
                </div>
                <v-form>
                  <v-text-field
                    append-icon="person"
                    name="username"
                    label="Escolha um Nome de Usuário"
                    type="text"
                    required
                    v-model="model.username"
                  ></v-text-field>
                  <v-text-field
                    append-icon="face"
                    name="nome"
                    label="Nome"
                    type="text"
                    required
                    v-model="model.nome"
                  ></v-text-field>
                  <v-text-field
                    append-icon="face"
                    name="sobrenome"
                    label="Sobrenome"
                    type="text"
                    required
                    v-model="model.sobrenome"
                  ></v-text-field>
                  <v-text-field
                    append-icon="fingerprint"
                    name="cpf"
                    label="CPF"
                    type="text"
                    required
                    v-model="model.cpf"
                  ></v-text-field>
                  <v-text-field
                    append-icon="phone_iphone"
                    name="celular"
                    label="Telefone Celular"
                    type="text"
                    required
                    v-model="model.celular"
                  ></v-text-field>
                  <v-text-field
                    append-icon="badge"
                    name="cro"
                    label="Registro profissional (CRO)"
                    type="text"
                    required
                    v-model="model.cro"
                  ></v-text-field>
                  <v-text-field
                    append-icon="map"
                    name="endereco"
                    label="Endereço do consultório"
                    type="text"
                    required
                    v-model="model.endereco"
                  ></v-text-field>
                  <v-text-field
                    append-icon="my_location"
                    name="cep"
                    label="CEP"
                    type="text"
                    required
                    v-model="model.cep"
                  ></v-text-field>
                  <v-text-field
                    append-icon="phone"
                    name="telefone"
                    label="Telefone do consultório"
                    type="text"
                    required
                    v-model="model.cep"
                  ></v-text-field>
                  <v-text-field
                    append-icon="location_city"
                    name="cidade"
                    label="Cidade"
                    type="text"
                    required
                    v-model="model.cep"
                  ></v-text-field>
                  <v-select
                    v-model="model.estado"
                    :items="estados"
                    label="Estado"
                    item-text="nome"
                    item-value="sigla"
                    single-line
                    required
                  ></v-select>
                  <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="model.estado"
                    :items="estados"
                    item-text="nome"
                    item-value="sigla"
                    label="Estado *"
                    box
                    required
                  ></v-autocomplete>
                </v-flex>
                  <v-text-field
                    name="pais"
                    label="Pais"
                    type="text"
                    required
                    v-model="model.pais"
                  ></v-text-field>
                  <v-text-field
                    name="email"
                    append-icon="email"
                    label="E-mail"
                    type="text"
                    required
                    v-model="model.email"
                  ></v-text-field>
                  <v-text-field
                    append-icon="lock"
                    name="password"
                    label="Senha"
                    type="password"
                    v-model="model.password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn block color="primary" @click="login" :loading="loading"
                  >Cadastrar-se</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import toastr from "toastr";
import API from "@/api/";
// import FotoCliente from "./comercial/FotoCliente.vue";
export default {
  // components: { FotoCliente },
  data: () => ({
    loading: false,
    e1: "",
    model: {
      username: "cidinho.cide@gmail.com",
      password: "Camila*2502",
      estado:{}
    },
    estados: [
        { nome: "Acre", sigla: "AC" },
        { nome: "Alagoas", sigla: "AL" },
        { nome: "Amapá", sigla: "AP" },
        { nome: "Amazonas", sigla: "AM" },
        { nome: "Bahia", sigla: "BA" },
        { nome: "Ceará", sigla: "CE" },
        { nome: "Distrito Federal", sigla: "DF" },
        { nome: "Espírito Santo", sigla: "ES" },
        { nome: "Goiás", sigla: "GO" },
        { nome: "Maranhão", sigla: "MA" },
        { nome: "Mato Grosso", sigla: "MT" },
        { nome: "Mato Grosso do Sul", sigla: "MS" },
        { nome: "Minas Gerais", sigla: "MG" },
        { nome: "Pará", sigla: "PA" },
        { nome: "Paraíba", sigla: "PB" },
        { nome: "Paraná", sigla: "PR" },
        { nome: "Pernambuco", sigla: "PE" },
        { nome: "Piauí", sigla: "PI" },
        { nome: "Rio de Janeiro", sigla: "RJ" },
        { nome: "Rio Grande do Norte", sigla: "RN" },
        { nome: "Rio Grande do Sul", sigla: "RS" },
        { nome: "Rondônia", sigla: "RO" },
        { nome: "Roraima", sigla: "RR" },
        { nome: "Santa Catarina", sigla: "SC" },
        { nome: "São Paulo", sigla: "SP" },
        { nome: "Sergipe", sigla: "SE" },
        { nome: "Tocantins", sigla: "TO" },
      ],
  }),
  methods: {
    login() {
      // console.log(this)
      this.loading = true;
      // this.$http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      // this.$http.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token';

      this.$http
        .post(this.$store.state.urlBase + "login", {
          // .post(this.$store.state.urlBase + "auth/login/" , {
          email: this.model.username,
          password: this.model.password,
        })
        .then((response) => {
          // console.log(response)
          if (response.data.token) {
            //Login com sucesso
            this.$session.start();
            this.$session.set("user", response.data);
            this.$store.state.user = response.data;
            this.$store.commit("setUser", response.data);
            API.setUserStorage(response.data);
            window.user = response.data;
            // window.getApp.$emit("APP_LOJA_GET");
            // window.getApp.$emit("APP_SAIDAS_CLIENTE_GET");
            window.getApp.$emit("APP_LOGIN_SUCCESS");
            //Login Não Existe
          } else if (typeof response.data.errors !== "undefined") {
            //Erros de Validação
            for (var error in response.data.errors) {
              toastr.error(
                response.data.errors[error],
                "AlignerPlan"
              );
            }
            this.loading = false;
          }
          //Zerando o Formulário
          this.model.username = "";
          this.model.password = "";
          // // $('.form-login').show('slow')
          // this.$store.commit('loading',false)
        });
      // .catch(error => {
      //     console.log(error)
      //     this.$store.commit('loading',false)
      //     toastr.error('Falha de conexão com a API','AlignerPlan')
      // })
    },
  },
};
</script>
<style scoped lang="css">
#credenciamento {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
.nome-empresa {
  line-height: 45px;
}
.logo-empresa {
}
</style>
