<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-layout row>
          <v-flex xs12 class="px-2">
            <!-- <v-btn color="primary" dark v-on="on">Open Dialog</v-btn> class="mx-2"-->
            <v-btn block color="primary" outline v-on="on">{{
              $t("Credenciamento")
            }}</v-btn>
          </v-flex>
        </v-layout>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="cancelar">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            $t("Registro de Novo Usuário")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat href="https://youtu.be/_H3UMA_la7k" target="_blank">
              <v-icon left>contact_support</v-icon> {{ $t("Ajuda") }}</v-btn>
          </v-toolbar-items>
          <v-toolbar-items>
            <v-btn dark flat @click="cadastrar">{{ $t("Cadastrar-se") }}</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-divider></v-divider>
        <v-card-text class="pt-2">
          <v-container fluid fill-height>
            <v-layout align-center justify-center>
              <v-flex xs12 sm12 md12 lg12>
                <v-form
                  ref="formCadastro"
                  v-model="valid"
                  lazy-validation
                  autocomplete="off"
                >
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      append-icon="face"
                      name="nome"
                      :label="$t('Nome')"
                      :counter="255"
                      :rules="rules.nome"
                      type="text"
                      required
                      v-model="model.nome"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      append-icon="face"
                      name="sobrenome"
                      :label="$t('Sobrenome')"
                      :counter="255"
                      :rules="rules.sobrenome"
                      type="text"
                      required
                      v-model="model.sobrenome"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      append-icon="fingerprint"
                      name="cpf"
                      :label="$t('CPF')"
                      type="text"
                      v-model="model.cpf"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      append-icon="phone_iphone"
                      name="celular"
                      :counter="13"
                      :label="$t('Telefone Celular')"
                      type="text"
                      mask="## (##) #####-####"
                      placeholder="55 (85) 99999-9999"
                      required
                      v-model="model.celular"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      append-icon="badge"
                      name="cro"
                      :label="$t('Registro profissional (CRO)')"
                      type="text"
                      required
                      v-model="model.cro"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      append-icon="map"
                      name="endereco"
                      :label="$t('Endereço do consultório')"
                      type="text"
                      required
                      :counter="255"
                      v-model="model.endereco"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      append-icon="my_location"
                      name="cep"
                      :label="$t('CEP')"
                      type="text"
                      required
                      v-model="model.cep"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      append-icon="phone"
                      name="telefone"
                      :label="$t('Telefone do consultório')"
                      type="text"
                      :counter="12"
                      mask="## (##) ####-####"
                      placeholder="55 (85) 9999-9999"
                      required
                      v-model="model.telefone"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      append-icon="location_city"
                      name="cidade"
                      :label="$t('Cidade')"
                      :counter="255"
                      type="text"
                      required
                      v-model="model.cidade"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <!-- <v-select
                      v-model="model.estado"
                      :items="$store.state.locale.locale === 'pt'? estados:estadosEs"
                      :label="$t('Estado')"
                      item-text="nome"
                      item-value="sigla"
                      :rules="rules.estado"
                      required
                    ></v-select> -->
                    <v-text-field
                      append-icon="location_city"
                      name="estado"
                      :label="$t('Estado')"
                      :counter="255"
                      type="text"
                      required
                      v-model="model.estado"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      name="pais"
                      :label="$t('Pais')"
                      type="text"
                      :counter="255"
                      required
                      v-model="model.pais"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      name="email"
                      append-icon="email"
                      :label="$t('Email')"
                      type="text"
                      :counter="191"
                      required
                      :rules="rules.email"
                      v-model="model.email"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      append-icon="lock"
                      name="senha"
                      :label="$t('Senha')"
                      type="password"
                      :counter="30"
                      :rules="rules.senha"
                      v-model="model.senha"
                    ></v-text-field>
                  </v-flex>
                </v-form>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat @click="cancelar"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary darken-3"
            @click="cadastrar"
            :loading="loading"
            >{{ $t("Cadastrar-se") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import toastr from "toastr";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      valid: true,
      notifications: false,
      sound: true,
      widgets: false,
      rules: {
        nome: [(v) => !!v || this.$t("O Campo Nome é obrigatorio!")],
        sobrenome: [(v) => !!v || this.$t("O Campo Sobrenome é obrigatorio!")],
        cpf: [(v) => !!v || this.$t("O Campo CPF é obrigatorio!")],
        celular: [(v) => !!v || this.$t("O Campo Celular é obrigatorio!")],
        cro: [
          (v) =>
            !!v ||
            this.$t("O Campo Registro Profissional (CRO) é obrigatorio!"),
        ],
        endereco: [
          (v) =>
            !!v || this.$t("O Campo Endereço do consultório é obrigatorio!"),
        ],
        cep: [(v) => !!v || this.$t("O Campo CEP é obrigatorio!")],
        telefone: [(v) => !!v || this.$t("O Campo Telefone é obrigatorio!")],
        cidade: [(v) => !!v || this.$t("O Campo Cidade é obrigatorio!")],
        estado: [(v) => !!v || this.$t("O Campo Estado é obrigatorio!")],
        pais: [(v) => !!v || this.$t("O Campo País é obrigatorio!")],
        email: [
          (v) => !!v || this.$t("O campo E-mail é obrigatorio!"),
          (v) =>
            /.+@.+/.test(v) ||
            this.$t("O campo E-mail precisa ser um endereço válido!"),
        ],
        senha: [(v) => !!v || this.$t("O Campo Senha é obrigatorio!")],
      },
      model: {},
      estados: [
        { nome: "Acre", sigla: "AC" },
        { nome: "Alagoas", sigla: "AL" },
        { nome: "Amapá", sigla: "AP" },
        { nome: "Amazonas", sigla: "AM" },
        { nome: "Bahia", sigla: "BA" },
        { nome: "Ceará", sigla: "CE" },
        { nome: "Distrito Federal", sigla: "DF" },
        { nome: "Espírito Santo", sigla: "ES" },
        { nome: "Goiás", sigla: "GO" },
        { nome: "Maranhão", sigla: "MA" },
        { nome: "Mato Grosso", sigla: "MT" },
        { nome: "Mato Grosso do Sul", sigla: "MS" },
        { nome: "Minas Gerais", sigla: "MG" },
        { nome: "Pará", sigla: "PA" },
        { nome: "Paraíba", sigla: "PB" },
        { nome: "Paraná", sigla: "PR" },
        { nome: "Pernambuco", sigla: "PE" },
        { nome: "Piauí", sigla: "PI" },
        { nome: "Rio de Janeiro", sigla: "RJ" },
        { nome: "Rio Grande do Norte", sigla: "RN" },
        { nome: "Rio Grande do Sul", sigla: "RS" },
        { nome: "Rondônia", sigla: "RO" },
        { nome: "Roraima", sigla: "RR" },
        { nome: "Santa Catarina", sigla: "SC" },
        { nome: "São Paulo", sigla: "SP" },
        { nome: "Sergipe", sigla: "SE" },
        { nome: "Tocantins", sigla: "TO" },
      ],
      estadosEs: [
        { nome: "Andalucía", sigla: "Andalucía" },
        { nome: "Aragón", sigla: "Aragón" },
        { nome: "Principado de Asturias", sigla: "Principado de Asturias" },
        { nome: "Balears", sigla: "Balears" },
        { nome: "País Vasco", sigla: "País Vasco" },
        { nome: "Islas Canarias", sigla: "Islas Canarias" },
        { nome: "Cantabria", sigla: "Cantabria" },
        { nome: "Cataluña", sigla: "Cataluña" },
        { nome: "Castilla-La Mancha", sigla: "Castilla-La Mancha" },
        { nome: "Castilla y León", sigla: "Castilla y León" },
        { nome: "Extremadura", sigla: "Extremadura" },
        { nome: "Galicia", sigla: "Galicia" },
        { nome: "La Rioja", sigla: "La Rioja" },
        { nome: "Comunidad de Madrid", sigla: "Comunidad de Madrid" },
        { nome: "Región de Murcia", sigla: "Región de Murcia" },
        { nome: "Nafarroa", sigla: "Nafarroa" },
        { nome: "Comunidad Valenciana", sigla: "Comunidad Valenciana" },
        { nome: "Fuera de Espana", sigla: "Fuera de Espana" },
      ],
    };
  },
  methods: {
    cadastrar() {
      if (this.$refs.formCadastro.validate()) {
        this.loading = true;
        console.log(this.model);
        let model = this.model;
        this.$http
          .post(this.$store.state.urlBase + "credenciamento", model)
          .then((response) => {
            console.log(response);
            if (response.data.valid) {
              toastr.success(
                this.$t(
                  "Credenciamento Efetuado! Efetue o login com e-mail e senha informados."
                ),
                "AlignerPlan"
              );
              this.$refs.formCadastro.reset();
              this.loading = false;
              this.dialog = false;
            } else if (typeof response.data.errors !== "undefined") {
              //Erros de Validação
              for (var error in response.data.errors) {
                toastr.error(response.data.errors[error], "AlignerPlan");
              }
              this.loading = false;
            }
          })
          .catch((error) => {
            this.loading = false;
            toastr.error(
              this.$t("Falha no envio do formulário!"),
              "AlignerPlan"
            );
          });
      } else {
        toastr.error(
          this.$t(
            "Preencha todos os campos obrigatórios antes de enviar o cadastro!"
          ),
          "AlignerPlan"
        );
      }
    },
    cancelar() {
      this.$refs.formCadastro.reset();
      this.dialog = false;
    },
  },
};
</script>