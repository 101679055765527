<template>
  <v-dialog v-model="$store.state.dialogUser" persistent max-width="500px">
    <v-btn slot="activator" color="primary" dark>
      <v-icon left>add_circle_outline</v-icon>
      Novo Usuário
    </v-btn>
    <v-card>
      <v-toolbar card color="primary" dark="">
        <v-toolbar-title class="body-2">Cadastro de usuário</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="$store.state.dialogUser = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-3">
        <v-form v-model="valid" ref="form" lazy-validation
          ><!--v-model="valid" ref="form" lazy-validation -->
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                append-icon="face"
                name="nome"
                :label="$t('Nome')"
                :counter="255"
                :rules="rules.nome"
                type="text"
                required
                v-model="$store.state.userEdit.name"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                append-icon="face"
                name="sobrenome"
                :label="$t('Sobrenome')"
                :counter="255"
                :rules="rules.sobrenome"
                type="text"
                required
                v-model="$store.state.userEdit.sobrenome"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                append-icon="fingerprint"
                name="cpf"
                :label="$t('CPF')"
                mask="###.###.###-##"
                :counter="11"
                type="text"
                placeholder="999.999.999-99"
                v-model="$store.state.userEdit.cpf"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                append-icon="phone_iphone"
                name="celular"
                :rules="rules.celular"
                :counter="13"
                :label="$t('Telefone Celular')"
                type="text"
                mask="## (##) #####-####"
                placeholder="55 (85) 99999-9999"
                required
                v-model="$store.state.userEdit.celular"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                append-icon="badge"
                name="cro"
                :label="$t('Registro profissional (CRO)')"
                type="text"
                required
                :rules="rules.cro"
                v-model="$store.state.userEdit.cro"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                append-icon="map"
                name="endereco"
                :label="$t('Endereço do consultório')"
                type="text"
                required
                :counter="255"
                :rules="rules.endereco"
                v-model="$store.state.userEdit.endereco"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                append-icon="my_location"
                name="cep"
                :label="$t('CEP')"
                type="text"
                required
                v-model="$store.state.userEdit.cep"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                append-icon="phone"
                name="telefone"
                :label="$t('Telefone do consultório')"
                type="text"
                :counter="12"
                mask="## (##) ####-####"
                placeholder="55 (85) 9999-9999"
                required
                :rules="rules.telefone"
                v-model="$store.state.userEdit.telefone"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                append-icon="location_city"
                name="cidade"
                :label="$t('Cidade')"
                :counter="255"
                type="text"
                required
                :rules="rules.cidade"
                v-model="$store.state.userEdit.cidade"
              ></v-text-field>
            </v-flex>
            <v-flex lg12 sm12>
              <v-text-field
                append-icon="location_city"
                name="estado"
                :label="$t('Estado')"
                :counter="255"
                type="text"
                required
                :rules="rules.estado"
                v-model="$store.state.userEdit.estado"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                name="pais"
                :label="$t('Pais')"
                type="text"
                :counter="255"
                required
                :rules="rules.pais"
                v-model="$store.state.userEdit.pais"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                name="email"
                append-icon="email"
                :label="$t('Email')"
                type="text"
                :counter="191"
                required
                :rules="rules.email"
                v-model="$store.state.userEdit.email"
              ></v-text-field>
            </v-flex>
            <v-flex lg12 sm12>
              <v-text-field
                label="Senha"
                v-model="$store.state.userEdit.password"
                type="password"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-select
                :items="tipo"
                label="Tipo"
                v-model="$store.state.userEdit.tipo"
                item-text="text"
                item-value="id"
              ></v-select>
            </v-flex>
            <v-flex lg12 sm12>
              <v-switch
                v-model="$store.state.userEdit.isAdmin"
                label="Administrador"
              ></v-switch>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-3">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" flat @click.native="cancelar()"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          @click.native="salvar()"
          v-if="!$store.state.userEdit.id"
          >Salvar</v-btn
        >
        <v-btn color="primary" @click.native="update()" v-else>Atualizar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
export default {
  name: "Usuario",
  data() {
    return {
      dialog: false,
      valid: true,
      rules: {
        nome: [(v) => !!v || this.$t("O Campo Nome é obrigatorio!")],
        sobrenome: [(v) => !!v || this.$t("O Campo Sobrenome é obrigatorio!")],
        cpf: [(v) => !!v || this.$t("O Campo CPF é obrigatorio!")],
        celular: [(v) => !!v || this.$t("O Campo Celular é obrigatorio!")],
        cro: [
          (v) =>
            !!v ||
            this.$t("O Campo Registro Profissional (CRO) é obrigatorio!"),
        ],
        endereco: [
          (v) =>
            !!v || this.$t("O Campo Endereço do consultório é obrigatorio!"),
        ],
        cep: [(v) => !!v || this.$t("O Campo CEP é obrigatorio!")],
        telefone: [(v) => !!v || this.$t("O Campo Telefone é obrigatorio!")],
        cidade: [(v) => !!v || this.$t("O Campo Cidade é obrigatorio!")],
        estado: [(v) => !!v || this.$t("O Campo Estado é obrigatorio!")],
        pais: [(v) => !!v || this.$t("O Campo País é obrigatorio!")],
        email: [
          (v) => !!v || this.$t("O campo E-mail é obrigatorio!"),
          (v) =>
            /.+@.+/.test(v) ||
            this.$t("O campo E-mail precisa ser um endereço válido!"),
        ],
        senha: [(v) => !!v || this.$t("O Campo Senha é obrigatorio!")],
      },
      tipo: [
        { text: "Administrador", id: 1 },
        { text: "Planejador", id: 2 },
        { text: "Revisor", id: 3 },
        { text: "Credenciado", id: 4 },
      ],
    };
  },
  methods: {
    salvar() {
      let user = this.$store.state.userEdit;
      toastr.success("Inserindo dados do usuário...", "Artaligner");
      this.$http
        .post(this.$store.state.urlBase + "users", user)
        .then((response) => {
          window.getApp.$emit("APP_RECORD_UPDATED");
          toastr.success("Usuário Inserido com sucesso...", "Artaligner");
          this.atualizar();
        });
    },
    update() {
      let user = this.$store.state.userEdit;
      console.log("Usuário:");
      console.log(user);
      toastr.success("Atualizando dados do usuário...", "Artaligner");
      // delete user.setor
      this.$http
        .put(this.$store.state.urlBase + "users/" + user.id, user)
        .then((response) => {
          window.getApp.$emit("APP_RECORD_UPDATED");
          toastr.success("Usuário Atualizado com sucesso...", "Artaligner");
          this.atualizar();
        });
    },
    cancelar() {
      this.$store.state.dialogUser = false;
      this.$store.state.userEdit = {};
    },
    atualizar() {
      this.$store.state.userEdit = {};
      //window.getApp.$emit("APP_USER_UPDATED");
      this.$store.state.dialogUser = false;
    },
  },
  created() {
    // console.log(!this.$store.state.categoria.id)
  },
};
</script>
