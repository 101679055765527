<template>
  <v-dialog
    v-model="$store.state.dialogCliente"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-btn slot="activator" color="primary" dark @click.native="novoCliente">
            <v-icon left>add_circle_outline</v-icon> 
            Novo Cliente
    </v-btn>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon flat dark @click="$store.state.dialogCliente = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="headline">Cliente</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark flat @click.native="salvar">Salvar</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <!-- <v-card-title>
                <span class="headline">Fornecedor de Sucata</span>
            </v-card-title> -->
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md8>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-if="!isUndefined($store.state.cliente.id)"
                    :disabled="!isUndefined($store.state.cliente.id)"
                    label="ID *"
                    v-model="$store.state.cliente.id"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    label="Nome Cliente *"
                    v-model="$store.state.cliente.name"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    label="E-mail *"
                    v-model="$store.state.cliente.email"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    label="Telefone"
                    v-model="$store.state.cliente.telefone"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    label="Celular"
                    v-model="$store.state.cliente.celular"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md6 v-if="$store.state.cliente.id">
                  <v-input
                    :messages="[toDateComplete($store.state.cliente.updated_at)]"
                    prepend-icon="update"
                  >
                    Última Atualização
                  </v-input>
                </v-flex>
                <v-flex xs12 sm12 md6 v-if="$store.state.cliente.id">
                  <v-input
                    :messages="[toDateComplete($store.state.cliente.created_at)]"
                    prepend-icon="more_time"
                  >
                    Data de Criação
                  </v-input>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-card class="ml-3 text-md-center" flat>
                    <v-avatar :tile="tile" :size="avatarSize">
                      <v-img
                        v-if="$store.state.mostrarAvatar && $store.state.cliente.id"
                        contain
                        :src="
                          $store.state.urlBaseWeb +
                          'logo/' +
                          $store.state.cliente.id
                        "
                      >
                      </v-img>
                    </v-avatar>
                    <v-card-title>
                      <!-- <div>
                        <span class="grey--text">Number 10</span><br />
                        <span>Whitehaven Beach</span><br />
                        <span>Whitsunday Island, Whitsunday Islands</span>
                      </div> -->
                    </v-card-title>
                    <v-card-actions class="text-md-center">
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 sm12 md12>
                            <!-- <foto-cliente v-if="$store.state.mostrarAvatar || $store.state.cliente.id"></foto-cliente> -->
                            <!-- <v-btn flat color="primary" class="text-md-center" block 
                              >Upload Logo</v-btn
                            > -->
                            <!-- <v-spacer></v-spacer> -->
                            <!-- <v-btn flat color="orange">Explore</v-btn> -->
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
        <small>* campo obrigatório</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" flat @click.native="atualizar">Sair</v-btn>
        <v-btn
          color="blue darken-1"
          flat
          @click.native="salvar"
          v-if="!$store.state.cliente.id"
          >Salvar
        </v-btn>
        <v-btn color="blue darken-1" flat @click.native="update" v-else
          >Atualizar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
// import FotoCliente from "@/pages/comercial/FotoCliente.vue";
export default {
  data() {
    return {
      modalDataEntrada: false,
      modalDataValidade: false,
      dialog: false,
      valor: 16,
      slider: 256,
      tile: false,
      valid:false,
      // mostrarAvatar: true,
    };
  },
  // components: { FotoCliente },
  computed: {
    avatarSize() {
      return `${this.slider}px`;
    },
    avatarURL() {
      this.$store.state.mostrarAvatar = false;
      return this.$store.state.cliente.logo;
    },
  },
  methods: {
    novoCliente(){
      this.$store.state.cliente = {}
      this.$store.state.mostrarAvatar = false;

    },
    isUndefined(value) {
      return typeof value === "undefined";
    },
    salvar() {
      let cliente = this.$store.state.cliente;
      if(this.$store.state.cliente.email){
        toastr.success('Inserindo dados do cliente...','Artaligner')
        this.$http
          .post(this.$store.state.urlBase + "clientes", cliente)
          .then((response) => {
              window.getApp.$emit("APP_RECORD_UPDATED");
              toastr.success('Cliente Inserido com sucesso...','Artaligner')
            this.atualizar();
          });
        this.$store.state.cliente = {};
        this.$store.commit("fecharModalCliente");
      }else{
        toastr.error('Não é possível inserir Cliente sem e-mail...','Artaligner')
      }
      
    },
    update() {
      toastr.success("Atualizando dados do cliente...", "Artaligner");
      // delete user.setor
      this.$http
        .put(
          this.$store.state.urlBase +
            "clientes/" +
            this.$store.state.cliente.id,
          this.$store.state.cliente
        )
        .then((response) => {
          window.getApp.$emit("APP_RECORD_UPDATED");
          toastr.success("Cliente Atualizado com sucesso...", "Artaligner");
          this.atualizar();
        });

      this.$store.state.cliente = {};
      this.$store.commit("fecharModalCliente");
    },
    alterarAvatar() {
      // alert("VIDEO HAS STOPPED");
      toastr.success("Carregando a Imagem!", "ArtAligner");
      this.$store.state.mostrarAvatar = true;
    },
    atualizarAvatar() {
      this.$store.state.mostrarAvatar = false;
      setTimeout(this.alterarAvatar, 1000);
    },
    atualizar() {
      this.$store.state.cliente = {};
      // window.getApp.$emit('APP_FORNECEDOR_SUCATA_UPDATED')
      // this.$store.state.dialogCliente = false
      this.$store.commit("fecharModalCliente");
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    toDateComplete(date){
      let newDate = new Date(date);
      return newDate.toLocaleDateString([], {hour: '2-digit', minute:'2-digit', second: '2-digit'});
    }
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toFixed(2);
    },

    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
  mounted() {},
  created() {
    window.getApp.$on("APP_AVATAR_UPDATED", this.atualizarAvatar);
  },
};
</script>
