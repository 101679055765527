<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h3>Clientes Espanha</h3>
        </v-flex>
        <v-flex lg12>
          <v-card>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <vue-json-to-csv :json-data="complex.items" :csv-title="'Usuários Espanha'">
                <v-btn color="success" class="mr-6">
                  Exportar
                  <i class="mdi mdi-export-variant" aria-hidden="true"></i>
                </v-btn>
              </vue-json-to-csv>
            </v-toolbar>
            <v-toolbar card color="white">
              <v-text-field
                flat
                solo
                prepend-icon="search"
                placeholder="Digite algo"
                v-model="search"
                hide-details
                class=""
                classeantiga="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :rows-per-page-items="[
                  10,
                  25,
                  50,
                  { text: 'Todos', value: -1 },
                ]"
                rows-per-page-text="Linhas por página"
                class="elevation-1"
                item-key="name"
                v-model="complex.selected"
              >
                <template
                  slot="items"
                  slot-scope="props"
                  @click.native="editar(props.item)"
                >
                  <td>{{ props.item.ID }}</td>
                  <td>{{ props.item.full_name }}</td>
                  <td>{{ props.item.user_email }}</td>
                  <td>{{ props.item.celular }}</td>
                  <td>{{ props.item.city }}</td>
                  <td>
                    <!-- <v-btn
                      depressed
                      outline
                      icon
                      fab
                      dark
                      color="primary"
                      small
                      @click.native="editar(props.item)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn
                      depressed
                      outline
                      icon
                      fab
                      dark
                      color="pink"
                      small
                      @click.native="excluir(props.item)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn> -->
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import toastr from "toastr";
import ClienteModal from "@/pages/comercial/Cliente.vue";
import * as moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");

export default {
  name: "ClientesEspanha",
  components: {
    ClienteModal,
    VueJsonToCsv
  },
  data() {
    return {
      dialog: false,
      search: "",
      complex: {
        selected: [],
        headers: [
          {
            text: "ID",
            value: "ID",
          },
          {
            text: "Cliente",
            value: "full_name",
          },
          {
            text: "E-mail",
            value: "user_email",
          },
          {
            text: "Telefone",
            value: "celular",
          },
          {
            text: "Cidade",
            value: "city",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    getList() {
      this.complex.items = [];
      toastr.success("Atualizando Lista de Clientes...", "AlignerPlan");
      this.$http
        .get(this.$store.state.urlBase + "clientesEspanha")
        //  .get(this.$store.state.urlBase + "user")
        .then((response) => {
          this.complex.items = response.data;
          // console.log(response.data);
        });
    },
    editar(item) {
      this.$store.commit("abrirModalCliente", item);
      // window.getApp.$emit('APP_PRECO_PADRAO_CLIENTE_GET')
      // window.getApp.$emit('APP_TIPO_SUCATA_SAIDA_GET')
      // window.getApp.$emit('APP_CLINTES_BALANCAS_GET')
      // window.getApp.$emit('APP_ITENS_SUCATA_GET')
    },
    excluir(cliente) {
      var http = this.$http;
      var url = this.$store.state.urlBase;
      toastr.warning(
        "<br /><button type='button' value='Sim' style='padding:2px 7px;border:1px solid;'>Sim</button><button type='button'  value='Não' style='padding:2px 7px;border:1px solid;margin-left:10px'>Não</button>",
        "Deseja realmente excluir este cliente?",
        {
          allowHtml: true,
          onclick: function (toast) {
            var value = toast.target.value;

            if (value == "Sim") {
              alert('Iniciando a exclusão... aguarde!')
              alertExclusaoCliente()
              http
                .delete(url + "clientes/" + cliente.id)
                .then((response) => {
                  toastr.success(response.data.message, "AlignerPlan");
                  window.getApp.$emit("APP_RECORD_UPDATED");
                });
            }
            toastr.remove();
            toastr.clear();
          },
        }
      );
    },
  },
  created() {
    this.getList();
    // window.getApp.$emit('APP_COMPRADORES_GET')
    // console.log(window.getApp._events)
    delete window.getApp._events.APP_CLIENTES_UPDATED;
    delete window.getApp._events.APP_RECORD_UPDATED;
    window.getApp.$on("APP_CLIENTES_ESPANHA_UPDATED", this.getList);
    window.getApp.$on("APP_RECORD_UPDATED", this.getList);
    window.alertExclusaoCliente = function(){
      toastr.success("Aguarde a exclusão do Cliente...", "AlignerPlan");
    }
  },
  updated() {
    //   this.getCategorias ()
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toFixed(2);
    },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
};
</script>